import React from 'react'
import Select from 'react-select'
import styled, { css } from 'styled-components'
import respond from '../../scss/abstracts/mediaqueries'
import RateInput from '../components/inputs/RateInput'
import ColorPicker from '../components/inputs/secondPage/ColorPicker'

const StyledSecondPage = styled.div`
margin-top: 5rem;
${respond(
   "tab-port",
   css`
      margin-top: 3rem;
   `
)}
   .select-label{
      padding-right: 3rem;
      display: grid;
      align-items: center;
      height: 100%;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin-bottom: 1rem;
      p{
         margin-top: 1rem;
         margin-bottom: 1rem;
      }
      ${respond(
         "nexus-7",
         css`
            grid-template-columns: 1fr;
         `
      )}
   }
   p{
      margin: 0;
      margin-top: 3rem;
      margin-bottom: 2rem;
      ${respond(
         "nexus-7",
         css`
            margin-top: 2rem;
            margin-bottom: 1rem;
         `
      )}
   }


`

const SecondPage = ({formData, setFormData}) => {
   
   const sunscreen = [
      {value: 'never', label: 'Never'},
      {value: 'sometimes', label: 'Sometimes'},
      {value: 'exposures', label: 'Only for long exposures'},
      {value: 'everyday', label: 'Everyday'}
   ]

   const describe_skin = [
      {value: 'dry', label: 'Dry'},
      {value: 'occasionally-dry', label: 'Occasionally Dry'},
      {value: 'combination', label: 'Combination'},
      {value: 'occasionally-oily', label: 'Occasionally Oily'},
      {value: 'very-oily', label: 'Very Oily'}
   ]


  return (
      <StyledSecondPage className='container'>
         <label className='select-label' htmlFor="how-often"><p>How often do you wear sunscreen on your face?</p><Select options={sunscreen} value={formData.sunscreen} onChange={(value) => setFormData({...formData, sunscreen: value})} /> </label>
         <label className='select-label' htmlFor="skin-type"><p>How would you describe your skin type?</p> <Select options={describe_skin} value={formData.describe_skin} onChange={(value) => setFormData({...formData, describe_skin: value})} /></label>
         <div className="radio-container">
            <p>What color matches your skin color the best?</p> <ColorPicker formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p>I have dark spots from sun damage or age.</p><RateInput name='dark_spots' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p>I have puffy eyes and/or dark undereye circles.</p><RateInput name='puffy_eyes' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p>I have dull skin or I no longer have that youthful glow.</p><RateInput name='dull_skin' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p>I have skin redness.</p><RateInput name='skin_redness' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p>I have uneven skin tone.</p><RateInput name='uneven_skin' formData={formData} setFormData={setFormData} />
         </div>
        
      </StyledSecondPage>
  )
}

export default SecondPage