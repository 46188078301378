import React from 'react'
import styled from 'styled-components'

const StyledFourthPage = styled.div`
margin-top: 5rem;

   input{
      padding: 2px 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
      height: 40px;
   }
   h2{
      text-transform: none;
   }

   .email-error{
      font-size: 14px;
      color: red;
   }
`

const FourthPage = ({ formData, setFormData }) => {
   const validateEmail = () => {

   }

   return (
      <StyledFourthPage className='container'>
         <h3>Enter your email address</h3>
         <input type="email" placeholder='E-mail' name='email' value={formData.email_address} onChange={() => setFormData({ ...formData, email_address: event.target.value })} />
         <span className="email-error hidden" id='email-error'>Please enter a valid email address</span>
      </StyledFourthPage>
   )
}

export default FourthPage