import React, { useState } from "react";
import styled from "styled-components";
import FifthPage from "../pages/FifthPage";
import FirstPage from "../pages/FirstPage";
import FourthPage from "../pages/FourthPage";
import SecondPage from "../pages/SecondPage";
import ThirdPage from "../pages/ThirdPage";

const StyledForm = styled.div`
padding-bottom: 6rem;
  main {
   margin: 0;
    h1 {
      text-align: center;
    }
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    button {
      border: none;
      background-color: #4b4b4b;
      border-radius: 1rem;
      color: #fff;
      width: max-content;
      height: 40px;
      padding: 0.5rem 1rem;
      transition: background 0.5s;
      &:disabled {
        visibility: hidden;
      }
      &:hover {
        background-color: #2c2c2c;
      }
    }
  }
  .hr {
    border: 1px solid #ccc;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

const Form = ({ globalForms, setGlobalForms, index, quantity, order_id }) => {
  const [page, setPage] = useState(0);

  const [formData, setFormData] = useState({
    order_id: order_id,
    first_name: "",
    last_name: "",
    gender: "",
    age: "",
    country: "",
    does_skincare: "",
    sunscreen: "",
    describe_skin: "",
    skin_color: "",
    dark_spots: "",
    puffy_eyes: "",
    dull_skin: "",
    skin_redness: "",
    uneven_skin: "",
    wrinkles: "",
    crows_feet: "",
    sagging_skin: "",
    visible_pores: "",
    smooth_texture: "",
    dry_skin: "",
    patchy_skin: "",
    tight_skin: "",
    sensitive_skin: "",
    email_address: "",
  });

  const checkFirst = () => {
    if (
      formData.first_name == "" ||
      formData.last_name == "" ||
      formData.gender == "" ||
      formData.age == "" ||
      formData.country == "" ||
      formData.does_skincare == ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkSecond = () => {
    if (
      formData.sunscreen == "" ||
      formData.describe_skin == "" ||
      formData.skin_color == "" ||
      formData.dark_spots == "" ||
      formData.puffy_eyes == "" ||
      formData.dull_skin == "" ||
      formData.skin_redness == "" ||
      formData.uneven_skin == ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkThird = () => {
    if (
      formData.wrinkles == "" ||
      formData.crows_feet == "" ||
      formData.sagging_skin == "" ||
      formData.visible_pores == "" ||
      formData.smooth_texture == "" ||
      formData.dry_skin == "" ||
      formData.patchy_skin == "" ||
      formData.tight_skin == "" ||
      formData.sensitive_skin == ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkFourth = () => {
    if (formData.email_address == "") {
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // async function submitToSalesJet() {
  //    try {
  //       const response = await fetch("https://sj-api.com/externalapp/track", {
  //          method: "POST",
  //          headers: {
  //             "Content-Type": "application/json",
  //             Authorization: process.env.API_KEY // YOUR API KEY GOES HERE
  //          },
  //          body: JSON.stringify({
  //             event_name: 'xceler8_form_submittion', // YOUR EVENT NAME GOES HERE,
  //             contact: {
  //                email: formData.email_address, // Your email field
  //                first_name: formData.first_name, //Your first name field
  //                last_name: formData.last_name, // Your last name field
  //                custom_attributes: {
  //                   "f1c27403-9a06-ed11-a9ab-ff1d79b08822": props.order_id,
  //                   "85fc75f5-7d06-ed11-a9ab-ff1d79b08822": formData.gender.label,
  //                   "8851289b-8906-ed11-a9ab-ff1d79b08822": formData.age.label,
  //                   "da70b3a5-8906-ed11-a9ab-ff1d79b08822": formData.country.label,
  //                   "cdba59af-8906-ed11-a9ab-ff1d79b08822": formData.does_skincare,
  //                   "d932ead6-8906-ed11-a9ab-ff1d79b08822": formData.sunscreen.label,
  //                   "8c76aae0-8906-ed11-a9ab-ff1d79b08822": formData.describe_skin.label,
  //                   "8d76aae0-8906-ed11-a9ab-ff1d79b08822": formData.skin_color,
  //                   "818aa7ee-8906-ed11-a9ab-ff1d79b08822": formData.dark_spots,
  //                   "828aa7ee-8906-ed11-a9ab-ff1d79b08822": formData.puffy_eyes,
  //                   "e48c89f8-8906-ed11-a9ab-ff1d79b08822": formData.dull_skin,
  //                   "e68c89f8-8906-ed11-a9ab-ff1d79b08822": formData.skin_redness,
  //                   "f8fdaf03-8a06-ed11-a9ab-ff1d79b08822": formData.uneven_skin,
  //                   "7eba9516-8a06-ed11-a9ab-ff1d79b08822": formData.wrinkles,
  //                   "7fba9516-8a06-ed11-a9ab-ff1d79b08822": formData.crows_feet,
  //                   "15838122-8a06-ed11-a9ab-ff1d79b08822": formData.sagging_skin,
  //                   "41c83e2a-8a06-ed11-a9ab-ff1d79b08822": formData.visible_pores,
  //                   "e1b2553e-8a06-ed11-a9ab-ff1d79b08822": formData.smooth_texture,
  //                   "99b50c45-8a06-ed11-a9ab-ff1d79b08822": formData.dry_skin,
  //                   "9ab50c45-8a06-ed11-a9ab-ff1d79b08822": formData.patchy_skin,
  //                   "24f7ed54-8a06-ed11-a9ab-ff1d79b08822": formData.tight_skin,
  //                   "cf05185e-8a06-ed11-a9ab-ff1d79b08822": formData.sensitive_skin
  //                }
  //             },
  //          }),
  //       });
  //    } catch {
  //       console.log('error');
  //    }
  // }

  const PageDisplay = () => {
    if (page === 0)
      return <FirstPage page={page} formData={formData} setFormData={setFormData} />;
    else if (page === 1)
      return <SecondPage page={page} formData={formData} setFormData={setFormData} />;
    else if (page === 2)
      return <ThirdPage page={page} formData={formData} setFormData={setFormData} />;
    else if (page === 3)
      return <FourthPage page={page} formData={formData} setFormData={setFormData} />;
    else if (page === 4) return <FifthPage />;
  };

  const renderTitle = () => {
   if(page !== 4){
      if(quantity > 1) return (<h2>Form for Xceler8 kit {index}</h2>)
      else return(<h2>Form for Xceler8 kit</h2>)
   }
  }
  return (
    <StyledForm>
      {quantity > 1 && <hr className="hr"></hr>}
      <main className="container">
        {/* {quantity > 1 ? (
          <h2>Form for Xceler8 kit {index}</h2>
        ) : (
          <h2>Form for Xceler8 kit</h2>
        )} */}

        {renderTitle()}

        {PageDisplay()}

        <div className="btn-container">
          <button
            disabled={page == 0 || page == 4}
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}>
            Previous Step
          </button>
          <button
            disabled={page == 4}
            onClick={() => {
              if (page === 0) {
                if (checkFirst() === false) {
                  document
                    .getElementById("error-message")
                    .classList.remove("d-none");
                } else {
                  document
                    .getElementById("error-message")
                    .classList.add("d-none");
                  // console.log(formData);
                  setPage((currPage) => currPage + 1);
                }
              } else if (page === 1) {
                if (checkSecond() === false) {
                  document
                    .getElementById("error-message")
                    .classList.remove("d-none");
                } else {
                  document
                    .getElementById("error-message")
                    .classList.add("d-none");
                  // console.log(globalForms);
                  setPage((currPage) => currPage + 1);
                }
              } else if (page === 2) {
                if (checkThird() === false) {
                  document
                    .getElementById("error-message")
                    .classList.remove("d-none");
                } else {
                  document
                    .getElementById("error-message")
                    .classList.add("d-none");
                  // console.log(globalForms);
                  setPage((currPage) => currPage + 1);
                }
              } else if (page === 3) {
                if (checkFourth() === false) {
                  document
                    .getElementById("error-message")
                    .classList.remove("d-none");
                } else if (!validateEmail(formData.email_address)) {
                  document
                    .getElementById("email-error")
                    .classList.remove("hidden");
                } else {
                  setGlobalForms((globalForms) => {
                    globalForms[index - 1] = {
                      isSubmitted: true,
                      formData: formData,
                    };
                    return [...globalForms];
                  });
                  document
                    .getElementById("email-error")
                    .classList.add("hidden");
                  document
                    .getElementById("error-message")
                    .classList.add("d-none");
                  document.querySelector(".instructions").classList.add("d-none")
                  setPage((currPage) => currPage + 1);
                }
              }
              // setPage((currPage) => currPage + 1)
            }}>
            {page === 3 ? "Submit" : "Next Step"}
          </button>
        </div>
      </main>
    </StyledForm>
  );
};

export default Form;
