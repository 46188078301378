import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import styled, { css } from 'styled-components'
import respond from '../../scss/abstracts/mediaqueries'


const SlyledFirstPage = styled.div`
   margin-top: 5rem;
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: repeat(3, 1fr);
   grid-gap: 1rem;
   width: 100%; 
   ${respond(
      "phone-land",
      css`
         margin-top: 3rem;
         grid-template-columns: 1fr;
      `
   )}

   input{
      padding: 2px 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-weight: 300;
   }
   .radio{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      
      &-question{
         padding-left: 1rem;
         width: 70%;
         display: flex;
         ${respond(
            "tab-port",
            css`
               font-size: 1.5rem;
            `
         )}
      }
      &-btns{
         display: flex;
         justify-content: space-around;
         flex-grow: 1;
         ${respond(
            "phone-land",
            css`
               justify-content: space-around;
            `
         )}
         label{
            display: flex;
            margin-right: 10%;
            ${respond(
               "tab-port",
               css`
                  font-size: 1.5rem;
               `
            )}
            ${respond(
               "phone-port",
               css`
                  margin-right: 12%;
                  flex-direction: column;
               `
            )}
            input{
               width: 2rem;
               margin-left: 0.4rem;
               ${respond(
                  "tab-port",
                  css`
                     width: 1.6rem;
                  `
               )}
            }
         }
      }
   }
`





const FirstPage = ({ formData, setFormData }) => {
   // options for gender selector
   const genders = [
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' }
   ]

   // options for age selector
   const ages = [
      { value: 18, label: 'Under 18' },
      { value: 24, label: '18-24' },
      { value: 34, label: '25-34' },
      { value: 44, label: '35-44' },
      { value: 54, label: '45-54' },
      { value: 64, label: '55-64' },
      { value: 65, label: '65+' }
   ]

   // options for country selector
   const countries = useMemo(() => countryList().getData(), [])



   return (
      <SlyledFirstPage className='container'>
         <input type="text" name="f-name" id="f-name" placeholder='First Name' value={formData.first_name} onChange={() => setFormData({ ...formData, first_name: event.target.value })} />
         <input type="text" name="l-name" id="l-name" placeholder='Last Name' value={formData.last_name} onChange={() => setFormData({ ...formData, last_name: event.target.value })} />
         <Select className='custom-select' options={genders} value={formData.gender} onChange={(value) => setFormData({ ...formData, gender: value })} />
         <Select className='custom-select' options={ages} value={formData.age} onChange={(value) => setFormData({ ...formData, age: value })} />
         <Select className='custom-select' options={countries} value={formData.country} onChange={(value) => setFormData({ ...formData, country: value })} />
         <div className="radio">
            <label className='radio-question'>Do you currently have a skin care routine?</label>
            <div className="radio-btns">
               <label>Yes<input type="radio" value={formData.does_skincare} onChange={() => setFormData({ ...formData, does_skincare: "yes" })} checked={formData.does_skincare === "yes"} /></label>
               <label>No<input type="radio" value={formData.does_skincare} onChange={() => setFormData({ ...formData, does_skincare: "no" })} checked={formData.does_skincare === "no"} /></label>
            </div>
         </div>

      </SlyledFirstPage>
   )
}

export default FirstPage