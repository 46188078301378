import React from "react";
import styled, { css } from "styled-components";
import respond from "../../../scss/abstracts/mediaqueries";

const StyledRateInput = styled.div`
  border: 1px solid #ccc;
  padding: 0.5rem 0;
  border-radius: 4px;

  .inputs-container {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
  }

  .input-container {
    display: grid;
    justify-content: center;
    input {
      margin: 0 auto;
      margin-top: 0.5rem;
      width: 20px;
      height: 20px;
      ${respond(
         "nexus-7",
         css`
            width: 15px;
            height: 15px;
         `
      )}
    }
    label {
      display: flex;
      justify-content: center;
    }
  }
  .description {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    p {
      display: flex;
      justify-content: center;
      margin: 0;
      font-size: 12px;
      &:last-of-type {
        grid-area: 1 / 11 / 2 / 12;
      }
    }
  }
`;

const RateInput = ({ name, formData, setFormData }) => {
  const array = new Array(11).fill(0);
  return (
    <StyledRateInput>
      <div className="inputs-container">
        {array.map((_, index) => {
         const stringIndex = JSON.stringify(index)
          return (
            <div className="input-container" key={index}>
              <label>{index}</label>
              <input
                type="radio"
                value={stringIndex}
                onChange={(e) =>
                  setFormData({ ...formData, [name]: e.target.value })
                }
                checked={formData[name] == stringIndex}
              />
            </div>
          );
        })}
      </div>
      <div className="description">
        <p>None</p>
        <p>Extreme</p>
      </div>
    </StyledRateInput>
  );
};

export default RateInput;
