import React from 'react'
import styled from 'styled-components'

const StyledColorPicker = styled.div`
width: 100%;
height: 6rem;
display: grid;
grid-template-columns: repeat(6, 1fr);
grid-gap: 1rem;

.color-label{
   display: inline-block;
  width: 100%;
  height: 100%;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    span { 
      transform: scale(1.05); 
    }
  }
  span {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform .2s ease-in-out;
    border: 1px solid #ccc;
  }
}

.ultra_light{
   background-color: #F9F4D8;
}

.super_light{
   background-color: #FBDAAB;
}

.light_skin{
   background-color: #F1C27C;
}

.medium_skin{
   background-color: #DFAD69;
}

.brown_skin{
   background-color: #C68642;
}

.dark_skin{
   background-color: #8C5524;
}


input[type="radio"] {
  display: none;
  &:checked + span { 
      transform: scale(1.05);
      border: 1px solid #4a4a4a;
   } // !&:checked + label
}
`

const ColorPicker = ({ formData, setFormData }) => {
   return (
      <StyledColorPicker>
         <label className='color-label'><input type="radio" value={formData.skin_color} onChange={() => setFormData({ ...formData, skin_color: "Ultra Light" })} checked={formData.skin_color === "Ultra Light"} /><span className="ultra_light"></span></label>
         <label className='color-label'><input type="radio" value={formData.skin_color} onChange={() => setFormData({ ...formData, skin_color: "Super Light" })} checked={formData.skin_color === "Super Light"} /><span className="super_light"></span></label>
         <label className='color-label'><input type="radio" value={formData.skin_color} onChange={() => setFormData({ ...formData, skin_color: "Light" })} checked={formData.skin_color === "Light"} /><span className="light_skin"></span></label>
         <label className='color-label'><input type="radio" value={formData.skin_color} onChange={() => setFormData({ ...formData, skin_color: "Medium Light" })} checked={formData.skin_color === "Medium Light"} /><span className="medium_skin"></span></label>
         <label className='color-label'><input type="radio" value={formData.skin_color} onChange={() => setFormData({ ...formData, skin_color: "Brown" })} checked={formData.skin_color === "Brown"} /><span className="brown_skin"></span></label>
         <label className='color-label'><input type="radio" value={formData.skin_color} onChange={() => setFormData({ ...formData, skin_color: "Dark" })} checked={formData.skin_color === "Dark"} /><span className="dark_skin"></span></label>
      </StyledColorPicker>
   )
}

export default ColorPicker