import React from 'react'
import styled, { css } from 'styled-components'
import respond from '../../scss/abstracts/mediaqueries'

const StyledFifhtPage = styled.main`
margin-top: 5rem;
${respond(
  "tab-port",
   css`
      margin-top: 3rem;
   `
)}
   p{
      width: 80%;
      display: block;
      margin: 0 auto;
   }
`

const FifthPage = () => {
   return (
         <StyledFifhtPage className='container'>
            <h2>Thank you!</h2>
            <h3>Analysis Complete!</h3>
            <p>Your Personalized Formula is ready! You will get email notifications about your order status.</p>
         </StyledFifhtPage>
   )
}

export default FifthPage