import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./js/components/App";
import FifthPage from "./js/pages/FifthPage";
import FirstPage from "./js/pages/FirstPage";
import FourthPage from "./js/pages/FourthPage";
import SecondPage from "./js/pages/SecondPage";
import ThirdPage from "./js/pages/ThirdPage";



if (module.hot) {
    module.hot.accept();
}

const container = document.getElementById('forms');
const root = createRoot(container);

root.render(
   <App />
);