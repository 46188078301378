import React from 'react'
import styled, { css } from 'styled-components'
import respond from '../../scss/abstracts/mediaqueries'
import RateInput from '../components/inputs/RateInput'

const StyledThirdPage = styled.main`
margin-top: 5rem;
${respond(
   "tab-port",
   css`
      margin-top: 3rem;
   `
)}

.radio-container{
   margin-top: 2rem;
}
`

const ThirdPage = ({formData, setFormData}) => {
  return (
      <StyledThirdPage className='container'>
         <div className="radio-container">
            <p className='radio-description'>I have fine lines and wrinkles.</p><RateInput name='wrinkles' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>I have crow's feet or deep wrinkled around the eye area.</p><RateInput name='crows_feet' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>I have sagging skin. It is no longer as firm and tight as before.</p><RateInput name='sagging_skin' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>I have visible pores.</p><RateInput name='visible_pores' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>My skin is not as smooth in texture as it once was.</p><RateInput name='smooth_texture' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>I have very dry skin.</p><RateInput name='dry_skin' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>I have rough or patchy skin.</p><RateInput name='patchy_skin' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>My skin feels tight or uncomfortable, especially after washing my face.</p><RateInput name='tight_skin' formData={formData} setFormData={setFormData} />
         </div>
         <div className="radio-container">
            <p className='radio-description'>I have sensitive skin and/or am easily irritated by skin products.</p><RateInput name='sensitive_skin' formData={formData} setFormData={setFormData} />
         </div>


      </StyledThirdPage>
  )
}

export default ThirdPage