import axios from "axios";
import React, { useRef, useState, useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import Form from "./Form";
import { ThreeDots } from "react-loader-spinner";
import respond from "../../scss/abstracts/mediaqueries";

const StyledApp = styled.div`
  .alert {
    margin-top: 4rem;
  }

  .instructions {
    margin-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
    font-size: 2.4rem;
   ${respond(
      "macbook-air",
      css`
         font-size: 2.2rem;
      `
   )}
   ${respond(
      "phone-land",
      css`
         font-size: 2rem;
      `
   )}
   ${respond(
      "ipad-port",
      css`
         font-size: 1.8rem;
      `
   )}
  }

  .loader-wrapper {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }
`;

const App = () => {
  // Catching quantity of Xceler8 from URL query param
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const quantity = params.xceler8_quan;
  const order_id = params.order_id;
  


  // Creating an array with all forms and states


const forms = new Array(+quantity).fill({ isSubmitted: false, formData: {} });
// console.log(forms);
  


  // Creating a state for amount of forms
  const [globalForms, setGlobalForms] = useState(forms);
  const firstUpdate = useRef(true);

  // Loader state
  const [loader, setLoader] = useState(false);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      // console.log("First render, skipping");
      firstUpdate.current = false;
      return;
    }
    const sendToApi = async () => {
      setLoader(true);
      const unsubmittedForms = globalForms.find((form) => !form.isSubmitted);
      if (!unsubmittedForms) {
        const response = await axios.post(
         //  `http://localhost:3005/api/submit-form`,
          `${window.location.origin}/api/submit-form`,
          globalForms
        );
        console.log(response);
      }
      setLoader(false);
    };
    sendToApi();
  }, [globalForms]);

  return (
    <StyledApp className="container">
      {quantity > 1 ? (
        <p className="instructions">
          Thank you for ordering Xceler8 kit! Below there are forms for each
          product you ordered. Please fill out all of them to get your
          personalized Xceler8!
        </p>
      ) : (
        <p className="instructions">
          Thank you for ordering Xceler8 kit! Fill out the form below to get
          your personalized Xceler8!
        </p>
      )}
      {forms.map((_, i) => {
        return (
          <Form
            key={i}
            index={i + 1}
            order_id={order_id}
            globalForms={globalForms}
            setGlobalForms={setGlobalForms}
            quantity={quantity}
          />
        );
      })}
      <div
        className="alert alert-danger d-none"
        role="alert"
        id="error-message">
        Please fill in all information!
      </div>
      {loader && (
        <div className="loader-wrapper">
          <ThreeDots
            height="100"
            width="100"
            color="#A87E1F"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
    </StyledApp>
  );
};

export default App;
